import { createAction, props } from '@ngrx/store';

import {
  CreateFundMovementRequest,
  FundMovement,
  UpdateFundMovementRequest,
} from '../../models';

export const loadByIdRequest = createAction(
  '[Fund Movements] Load By Id Request',
  props<{ propertyId: number; itemId: number }>(),
);

export const loadByIdSuccess = createAction(
  '[Fund Movements] Load By Id Success',
  props<{ item: FundMovement }>(),
);

export const loadByIdFailure = createAction(
  '[Fund Movements] Load By id Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Fund Movements] Delete Request',
  props<{ propertyId: number; itemId: number }>(),
);

export const deleteSuccess = createAction(
  '[Fund Movements] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Fund Movements] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Fund Movements] Create Request',
  props<{ request: CreateFundMovementRequest }>(),
);

export const createSuccess = createAction(
  '[Fund Movements] Create Success',
  props<{ item: FundMovement }>(),
);

export const createFailure = createAction(
  '[Fund Movements] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Fund Movements] Update Request',
  props<{ request: UpdateFundMovementRequest }>(),
);

export const updateSuccess = createAction(
  '[Fund Movements] Update Success',
  props<{ item: FundMovement }>(),
);

export const updateFailure = createAction(
  '[Fund Movements] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Fund Movements] Reset State');
