import {
  CashDeskData,
  HttpExportData,
  ICashDeskSingleMovement,
  ICashDeskSingleMovementRequest,
  ICashDeskWidget,
  SearchParams,
} from '@app/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Cash Desk] Load Request',
  LOAD_FAILURE = '[Cash Desk] Load Failure',
  LOAD_SUCCESS = '[Cash Desk] Load Success',

  EXPORT_REQUEST = '[Cash Desk] Export Request',
  EXPORT_FAILURE = '[Cash Desk] Export Failure',
  EXPORT_SUCCESS = '[Cash Desk] Export Success',

  LOAD_WIDGET_REQUEST = '[Cash Desk] Load Widget Request',
  LOAD_WIDGET_FAILURE = '[Cash Desk] Load Widget Failure',
  LOAD_WIDGET_SUCCESS = '[Cash Desk] Load Widget Success',

  LOAD_MOVEMENT_REQUEST = '[Cash Desk] Load Movement Request',
  LOAD_MOVEMENT_FAILURE = '[Cash Desk] Load Movement Failure',
  LOAD_MOVEMENT_SUCCESS = '[Cash Desk] Load Movement Success',

  CREATE_REQUEST = '[Cash Desk] Create Request',
  CREATE_FAILURE = '[Cash Desk] Create Failure',
  CREATE_SUCCESS = '[Cash Desk] Create Success',

  DELETE_REQUEST = '[Cash Desk] Delete Request',
  DELETE_FAILURE = '[Cash Desk] Delete Failure',
  DELETE_SUCCESS = '[Cash Desk] Delete Success',

  UPDATE_REQUEST = '[Cash Desk] Update Request',
  UPDATE_FAILURE = '[Cash Desk] Update Failure',
  UPDATE_SUCCESS = '[Cash Desk] Update Success',

  RESET_STATE = '[Cash Desk] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: {
      params: SearchParams;
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public payload: { items: CashDeskData }) {}
}

export class ExportRequestAction implements Action {
  readonly type = ActionTypes.EXPORT_REQUEST;
  constructor(
    public payload: {
      propertiesIDS: number[];
      isDownload: boolean;
      data: any;
    },
  ) {}
}

export class ExportFailureAction implements Action {
  readonly type = ActionTypes.EXPORT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ExportSuccessAction implements Action {
  readonly type = ActionTypes.EXPORT_SUCCESS;
  constructor(public payload: { exportID: number }) {}
}

export class LoadWidgetRequestAction implements Action {
  readonly type = ActionTypes.LOAD_WIDGET_REQUEST;
  constructor(
    public payload: {
      params: SearchParams;
    },
  ) {}
}

export class LoadWidgetFailureAction implements Action {
  readonly type = ActionTypes.LOAD_WIDGET_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadWidgetSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_WIDGET_SUCCESS;
  constructor(public payload: { items: ICashDeskWidget }) {}
}
export class LoadMovementRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MOVEMENT_REQUEST;
  constructor(
    public payload: {
      property_id: number;
      property_cash_movement_id: number;
    },
  ) {}
}

export class LoadMovementFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MOVEMENT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadMovementSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MOVEMENT_SUCCESS;
  constructor(public payload: { items: ICashDeskSingleMovement }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: ICashDeskSingleMovementRequest }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { data?: ICashDeskSingleMovement }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: {
      property_id: number;
      property_cash_movement_id: number;
    },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(
    public payload: { paymentId?: number; payment_method_id?: number },
  ) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      request: ICashDeskSingleMovementRequest;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { data?: ICashDeskSingleMovement }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ExportRequestAction
  | ExportFailureAction
  | ExportSuccessAction
  | LoadMovementRequestAction
  | LoadMovementFailureAction
  | LoadMovementSuccessAction
  | LoadWidgetRequestAction
  | LoadWidgetFailureAction
  | LoadWidgetSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ResetSuccessAction;
