import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FundMovement } from '../../models';
import { FundMovementsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class FundMovementsStoreEffects {
  constructor(
    private dataService: FundMovementsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadByIdRequest),
      switchMap(({ propertyId, itemId }) =>
        this.dataService.loadById(propertyId, itemId).pipe(
          map((response: FundMovement) =>
            fromActions.loadByIdSuccess({
              item: response,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadByIdFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ propertyId, itemId }) =>
        this.dataService.delete(propertyId, itemId).pipe(
          map(() => {
            this.notifications.success('delete_success', 'fund_movement');
            return fromActions.deleteSuccess({
              itemId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request }) =>
        this.dataService.create(request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.success('create_success', 'fund_movement');
            // this.router.navigate(['/configuration/invoicing/fiscal-printers']);
            return fromActions.createSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.success('update_success', 'fund_movement');
            // this.router.navigate(['/configuration/invoicing/fiscal-printers']);
            return fromActions.updateSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );
}
