import { get, mapValues } from 'lodash';

import { floatRound } from '../../helpers/float-round';
import {
  CashDeskData,
  ICashDeskWidget,
  List,
  StatsHeaderWidget,
  Translation,
} from '../../models';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;

      const documentType = (document: List): Translation[] => {
        const { rows, category, type, document_refunds } = document;

        switch (true) {
          case rows && rows.length > 0: {
            return rows.map((row) => {
              const { reversal, document_type } = row;
              return !reversal
                ? { phrase: document_type }
                : { phrase: 'reversal_receipt' };
            });
          }
          case category === 'deposit': {
            return [{ phrase: 'deposit' }];
          }

          case category === 'payment' &&
            type === 'out' &&
            !rows?.length &&
            !document_refunds?.length: {
            return [{ phrase: 'refund' }];
          }
          case category === 'payment' &&
            type === 'out' &&
            !rows?.length &&
            !!document_refunds?.length: {
            return document_refunds.map(
              ({ type: typeDocument, number_complete }) => {
                return {
                  phrase: 'refund_document',
                  params: {
                    docType: typeDocument,
                    docNumber: number_complete,
                  },
                };
              },
            );
          }
          default: {
            return null;
          }
        }
      };

      const newItems = mapValues(items, (cashDataValues, key) => {
        if (key !== 'movements') {
          return cashDataValues;
        }
        return mapValues(cashDataValues, (cashDataValue, _) => {
          return mapValues(
            cashDataValue,
            (yearValues: any, yearKey: string) => {
              if (yearKey !== 'months') {
                return yearValues;
              }
              return mapValues(yearValues, (monthValues, __) => {
                return mapValues(monthValues, (monthValue, monthKey) => {
                  if (monthKey !== 'days') {
                    return monthValue;
                  }
                  return mapValues(monthValue, (dayValues, ___) => {
                    return mapValues(dayValues, (dayValue, dayKey) => {
                      if (dayKey !== 'list') {
                        return dayValue;
                      }
                      return dayValue.map((document: List) => {
                        return {
                          ...document,
                          movementType: documentType(document),
                        };
                      });
                    });
                  });
                });
              });
            },
          );
        });
      }) as CashDeskData;

      return {
        ...state,
        data: newItems,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.EXPORT_SUCCESS: {
      return {
        ...state,
        exportID: action.payload.exportID,
      };
    }

    case ActionTypes.LOAD_WIDGET_REQUEST: {
      return {
        ...state,
        isWidgetLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_WIDGET_SUCCESS: {
      return {
        ...state,
        widget: createWidgetDataStats(action.payload.items),
        isWidgetLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_WIDGET_FAILURE: {
      return {
        ...state,
        isWidgetLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_MOVEMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_MOVEMENT_SUCCESS: {
      return {
        ...state,
        singleMovement: action.payload.items,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_MOVEMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        singleMovement: action.payload.data,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return { ...state, isLoading: true, error: null };
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}

function createWidgetDataStats(data: ICashDeskWidget): StatsHeaderWidget[] {
  const widgets = [
    { key: 'cash.in', label: 'cash_in', class: 'color--green' },
    { key: 'cash.out', label: 'cash_out', class: 'color--red' },
    { key: 'cash.balance', label: 'cash_balance', class: 'color--zorro-azure' },
    { key: 'funds.balance', label: 'cash_fund', noSubtitle: true },
  ];

  return widgets.map((widget) => {
    const item = get(data, widget.key);
    const title = {
      key: widget.label,
      params: {},
      class: widget?.class,
    };
    const prefixIcon =
      item.percentage >= 0
        ? 'fas fa-arrow-alt-up color--green'
        : 'fas fa-arrow-alt-down color--red';

    let subtitle: any = '';
    if (item.previous === 0 && !widget.noSubtitle) {
      subtitle = {
        key: `no_value_more_than_last_year`,
        params: {},
      };
    }

    if (item.previous > 0 && !widget.noSubtitle) {
      subtitle = {
        key: `more_than_last_year`,
        params: {
          percent_value: floatRound(item.percentage),
        },
        prefixIcon,
      };
    }
    return {
      value: item.total,
      count: item.number,
      title,
      subtitle,
      valueType: 'currency',
    };
  });
}
