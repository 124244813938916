import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { CashDeskService } from '@app/services/cash-desk.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';
import { ExportService } from '../../services/export.service';

@Injectable()
export class CashDeskStoreEffects {
  constructor(
    private actions$: Actions,
    private _translate: TranslateService,
    private dataService: CashDeskService,
    private exportService: ExportService,
    private errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap(({ payload: { params } }: featureActions.LoadRequestAction) =>
        this.dataService.load({ ...params, view: 'view' }).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this._notifications.push({
              title: this._translate.instant('error'),
              content: this._translate.instant('notifications.load_failure', {
                param: this._translate.instant('cash_desk'),
              }),
              type: 'error',
            });
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  exportRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.ExportRequestAction>(
        featureActions.ActionTypes.EXPORT_REQUEST,
      ),
      switchMap(
        ({
          payload: { propertiesIDS, data, isDownload },
        }: featureActions.ExportRequestAction) =>
          this.dataService.export(propertiesIDS, data).pipe(
            map(({ data }: any) => {
              if (isDownload) {
                return new featureActions.ExportSuccessAction({
                  exportID: data.export_ids[0],
                });
              } else {
                this.exportService.directExport(data.viewUrl);
                return new featureActions.ExportSuccessAction({
                  exportID: null,
                });
              }
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.ExportFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  loadWidgetRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadWidgetRequestAction>(
        featureActions.ActionTypes.LOAD_WIDGET_REQUEST,
      ),
      switchMap(
        ({ payload: { params } }: featureActions.LoadWidgetRequestAction) =>
          this.dataService.load({ ...params, view: 'widget' }).pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadWidgetSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant('notifications.load_failure', {
                  param: this._translate.instant('cash_desk'),
                }),
                type: 'error',
              });
              return of(new featureActions.LoadWidgetFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  loadSingleMovementRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadMovementRequestAction>(
        featureActions.ActionTypes.LOAD_MOVEMENT_REQUEST,
      ),
      switchMap((action: featureActions.LoadMovementRequestAction) =>
        this.dataService
          .loadSingleMovement(
            action.payload.property_id,
            action.payload.property_cash_movement_id,
          )
          .pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadMovementSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant('notifications.load_failure', {
                  param: this._translate.instant('movement'),
                }),
                type: 'error',
              });
              return of(
                new featureActions.LoadMovementFailureAction({ error }),
              );
            }),
          ),
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap(
        ({ payload: { request } }: featureActions.CreateRequestAction) =>
          this.dataService.createSingleMovement(request).pipe(
            mergeMap(({ data }: any) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('cash_desk'),
                  },
                ),
                type: 'success',
              });
              return [new featureActions.CreateSuccessAction({ data })];
            }),
            catchError((error) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.create_failure',
                  {
                    param: this._translate.instant('casch_desk'),
                  },
                ),
                type: 'error',
              });
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { property_cash_movement_id },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.deleteSingleMovement(property_cash_movement_id).pipe(
            mergeMap(() => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.delete_success',
                  {
                    param: this._translate.instant('cash_movement'),
                  },
                ),
                type: 'success',
              });
              return [new featureActions.DeleteSuccessAction({})];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({ payload: { request } }: featureActions.UpdateRequestAction) =>
          this.dataService
            .updateSingleMovement(
              request.property_id[0],
              request.property_cash_movement_id,
              request,
            )
            .pipe(
              mergeMap((response: IResponseSuccess) => {
                this._notifications.push({
                  title: this._translate.instant('done'),
                  content: this._translate.instant(
                    'notifications.update_success',
                    {
                      param: this._translate.instant('cash_desk'),
                    },
                  ),
                  type: 'success',
                });
                return [new featureActions.UpdateSuccessAction({})];
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(new featureActions.UpdateFailureAction({ error }));
              }),
            ),
      ),
    ),
  );
}
