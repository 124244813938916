import {
  CashDeskData,
  ICashDeskSingleMovement,
  StatsHeaderWidget,
} from '../../models';

export interface State {
  isLoading?: boolean;
  error?: any;
  data: CashDeskData;
  widget: StatsHeaderWidget[];
  exportID: number;
  isWidgetLoading?: boolean;
  singleMovement: ICashDeskSingleMovement;
}

export const initialState: State = {
  isLoading: false,
  isWidgetLoading: false,
  error: null,
  data: null,
  widget: null,
  exportID: null,
  singleMovement: null,
};
