import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { ICashDeskSingleMovementRequest } from '@app/models';

import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class CashDeskService {
  constructor(private http: HttpClient) {}

  load(params: SearchParams) {
    return this.http.get(`properties/cash_desk?${generateSearchQuery(params)}`);
  }

  export(propertiesIDS: number[], data: any) {
    return this.http.post(
      `properties/print_cash_desk?${generateParamArray(
        'property_id',
        propertiesIDS,
      )}`,
      data,
    );
  }

  loadSingleMovement(property_id: number, property_cash_movement_id: number) {
    return this.http.get(
      `property/${property_id}/property_cash_movement/${property_cash_movement_id}`,
    );
  }
  createSingleMovement(data: ICashDeskSingleMovementRequest) {
    return this.http.post(`properties/cash_movement`, data);
  }

  updateSingleMovement(
    property_id: number,
    property_cash_movement_id: number,
    data: ICashDeskSingleMovementRequest,
  ) {
    return this.http.put(
      `property/${property_id}/property_cash_movement/${property_cash_movement_id}`,
      data,
    );
  }

  deleteSingleMovement(property_cash_movement_id: number) {
    return this.http.delete(`payment/${property_cash_movement_id}`);
  }
}
