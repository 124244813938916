import {
  CashDeskData,
  ICashDeskSingleMovement,
  ICashDeskWidget,
  StatsHeaderWidget,
} from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;
export const getIsWidgetLoading = (state: State): boolean =>
  state.isWidgetLoading;

export const getData = (state: State): CashDeskData => state.data;
export const getWidget = (state: State): StatsHeaderWidget[] => state.widget;
export const getSingleMovement = (state: State): ICashDeskSingleMovement =>
  state.singleMovement;

export const selectCashDeskState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('cashDesk');

export const selectCashDeskError: MemoizedSelector<object, any> =
  createSelector(selectCashDeskState, getError);
export const selectAllCashDeskItems: MemoizedSelector<object, any> =
  createSelector(selectCashDeskState, getData);
export const selectCashDeskWidget: MemoizedSelector<object, any> =
  createSelector(selectCashDeskState, getWidget);
export const selectCashDeskSingleMovement: MemoizedSelector<object, any> =
  createSelector(selectCashDeskState, getSingleMovement);

export const selectCashDeskIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCashDeskState, getIsLoading);

export const selectCashDeskWidgetIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCashDeskState, getIsWidgetLoading);

export const selectExportID: MemoizedSelector<object, number> = createSelector(
  selectCashDeskState,
  ({ exportID }) => exportID,
);
